/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Sub-processors"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Updated"), ": October 1st, 2024"), "\n", React.createElement(_components.p, null, "At Flottando, we engage third-party service providers (Sub-processors) to assist in the processing of personal data on behalf of our customers in connection with our software-as-a-service (SaaS) products. These Sub-processors provide various services, such as hosting, data storage, and security, to ensure the seamless operation of our platform."), "\n", React.createElement(_components.p, null, "Below is the current list of Sub-processors that Flottando engages for the processing of personal data:"), "\n", React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Sub-processor"), "\n", React.createElement("th", null, "Service Provided"), "\n", React.createElement("th", null, "Location"), "\n", React.createElement("th", null, "Notes"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Amazon Web Services EMEA SARL (\"AWS Europe\")"), "\n", React.createElement("td", null, "Cloud Service Provider"), "\n", React.createElement("td", null, "38 avenue John F. Kennedy, 1855, Luxembourg"), "\n", React.createElement("td", null, "Data hosted in EU region only")))), "\n", React.createElement(_components.p, null, "We update this list periodically as necessary to reflect any changes in our data processing activities. For further information on how Flottando handles personal data or to inquire about our Sub-processors, please contact us at ", React.createElement(_components.a, {
    href: "mailto:privacy@flottando.com"
  }, "privacy@flottando.com"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
