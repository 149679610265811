import type { HeadFC } from 'gatsby'
import React from 'react'

import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import SubProcessors_DE from '../content/de/subProcessors.mdx'
import SubProcessors_EN from '../content/en/subProcessors.mdx'
import SubProcessors_ES from '../content/es/subProcessors.mdx'
import SubProcessors_FR from '../content/fr/subProcessors.mdx'
import SubProcessors_IT from '../content/it/subProcessors.mdx'

const SubProcessorsPage = ({ pageContext }: any) => {
  const language = pageContext.language

  return (
    <Layout light>
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row p-4 lg:px-0 pt-24 prose">
        {language === 'en' && <SubProcessors_EN />}
        {language === 'it' && <SubProcessors_IT />}
        {language === 'fr' && <SubProcessors_FR />}
        {language === 'es' && <SubProcessors_ES />}
        {language === 'de' && <SubProcessors_DE />}
      </main>
    </Layout>
  )
}

export default SubProcessorsPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['legal.sub_processors.title']}</title>
      <meta name="description" content={translations['legal.sub_processors.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
