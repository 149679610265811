/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    br: "br",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Sous-traitants"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Mise à jour"), ": 1er octobre 2024", React.createElement(_components.br), "\n", "Chez Flottando, nous faisons appel à des prestataires de services tiers (Sous-traitants) pour nous aider à traiter les données personnelles pour le compte de nos clients en relation avec nos produits software-as-a-service (SaaS). Ces Sous-traitants fournissent divers services, tels que l'hébergement, le stockage des données et la sécurité, pour assurer le bon fonctionnement de notre plateforme.", React.createElement(_components.br), "\n", "Voici la liste actuelle des Sous-traitants que Flottando engage pour le traitement des données personnelles :"), "\n", React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Sous-traitant"), " ", React.createElement("th", null, "Service Fourni"), " ", React.createElement("th", null, "Emplacement"), " ", React.createElement("th", null, "Remarques"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Amazon Web Services EMEA SARL (\"AWS Europe\")"), " ", React.createElement("td", null, "Fournisseur de services cloud"), "\n", React.createElement("td", null, "38 avenue John F. Kennedy, 1855, Luxembourg"), " ", React.createElement("td", null, "Données hébergées uniquement dans la région UE")))), "\n", React.createElement(_components.p, null, "Nous mettons à jour cette liste périodiquement pour refléter tout changement dans nos activités de traitement des données.\nPour plus d'informations sur la manière dont Flottando gère les données personnelles ou pour vous renseigner sur nos Sous-traitants,\nveuillez nous contacter à ", React.createElement(_components.a, {
    href: "mailto:privacy@flottando.com"
  }, "privacy@flottando.com"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
